<template>
  <div class="flex flex-col pb-32 px-4">
    <header class="text-left font-bold border-b border-gray">
      <button @click="$router.go(-1)" class="flex select-none items-center justify-start space-x-2 py-4 px-4 text-black">
        <i class="icon-arrowLeft" />
        <span class="flex flex-col flex-grow items-start">
          <span class="uppercase cursor-pointer font-semibold">
            Atrás
          </span>
        </span>
      </button>
    </header>

    <div class="w-full mt-8">
      <div class="flex text-left items-center mb-2">
        <h3 class="text-left text-xl font-bold text-onboarding_gray">Mi balance</h3>
        <div class="flex items-center ml-2 justify-center bg-yellow_ribbon rounded-full h-5 w-5">
          <i class="pi pi-star" style="font-size: 11px"></i>
        </div>
      </div>
      <div class="flex flex-col p-4 bg-onboarding_gray rounded-md items-center">
        <div>
          <h2 v-if="!loadingBalance" class="text-2xl text-white">{{ balance.toFixed(2).replace('.', ',') + '€' }}</h2>
          <a-loader v-else class="h-6 w-6 block mx-auto mb-4" :color="'text-white'"/>
          <h4 v-if="balance < 50" class="text-onboarding_light_gray mb-2 -mt-1">*retiro mínimo: 50,00€</h4>
        </div>
        <button
            @click="doWithdraw"
            class="button text-lg"
            :class="pendingAction ? 'bg-yellow_ribbon text-black' : 'bg-primary text-white'"
            :disabled="balance < 50 || loadingWithdraw || pendingAction"
            :style="balance < 50 || loadingWithdraw || pendingAction ? 'opacity: 0.8' : ''">
          <a-loader v-if="loadingWithdraw" class="h-6 w-6 block mx-auto" :color="'text-white'"/>
          <span v-else>{{ pendingAction ? 'Retiro en proceso' : 'Solicitar retiro' }}</span>
        </button>
      </div>
    </div>

    <div class="text-left text-xl mt-6 mb-2 text-onboarding_gray font-bold">
      <h2>Historial</h2>
    </div>

    <table v-if="!loadingBalance" class="text-left w-100">
      <tr class="text-onboarding_light_gray">
        <th class="text-left" style="width: 100px;">Fecha</th>
        <th class="text-left">Descripción</th>
        <th class="text-right">Importe</th>
      </tr>
      <tr v-for="movement in movements" :key="movement" class="border-t text-lg text-black" :class="movement.type === 'withdrawal' ? 'bg-yellow_ribbon' : ''">
        <td class="py-2 text-left">{{ $filters.moment(movement?.created_at, 'DD/MM/YY') }}</td>
        <td class="py-2 text-left">{{ movement?.description }}</td>
        <td v-if="movement.type === 'withdrawal'" class="py-2 text-right">{{ '(' + $filters.currencyFormatter(Math.abs(movement?.amount_e2 / 100)) + '€)' }}</td>
        <td v-else class="py-2 text-right">{{ $filters.currencyFormatter((movement?.amount_e2 / 100)) + '€' }}</td>
      </tr>
    </table>
    <a-loader v-else class="h-6 w-6 block mx-auto mb-4" :color="'text-black'"/>

    <a-alert
        :show="showModal"
        hide-cancel
        @confirm="getPendingAction"
        :confirm-text="'Aceptar'"
        :title="'Retiro solicitado'"
    >
      <div class="text-center mx-auto mt-2">
        <div class="space-x-2">
          <p>
            Hemos recibido tu solicitud. En breve recibirás un correo con instrucciones.
          </p>
        </div>
      </div>
    </a-alert>
    <a-alert
        :show="failed"
        hide-cancel
        :confirm-text="'Aceptar'"
        @confirm="failed = false">
      <h1 class="text-SFRed text-lg text-center">Ha ocurrido un error</h1>
      <h4 class="text-lg">
        Se produjo un error al intentar procesar su solicitud, por favor inténtelo más tarde.
      </h4>
    </a-alert>
  </div>
</template>

<script>

import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRepository } from '../../../../hooks'

export default {
  name: 'index',
  setup () {
    const balance = ref(0)
    const repository = useRepository(({ balance }) => balance)
    const store = useStore()
    const movements = ref([])
    const loadingBalance = ref(false)
    const loadingWithdraw = ref(false)
    const failed = ref(false)
    const showModal = ref(false)
    const pendingAction = ref(false)

    const doWithdraw = () => {
      loadingWithdraw.value = true
      repository.withdraw(store.state.foodies.current.id)
        .then((data) => {
          showModal.value = true
        })
        .catch(() => {
          failed.value = true
        })
        .finally(() => {
          loadingWithdraw.value = false
        })
    }

    const getPendingAction = () => {
      showModal.value = false
      return repository.pending(store.state.foodies.current.id)
        .then(({ data }) => {
          pendingAction.value = Object.keys(data).length !== 0
        })
    }

    onMounted(() => {
      loadingBalance.value = true
      // getPendingAction()
      getPendingAction()
        .then(() => {
          repository.historic(store.state.foodies.current.id)
            .then(({ data }) => {
              movements.value = data.data
              balance.value = data?.data[0]?.final_amount_e2 ? data.data[0].final_amount_e2 / 100 : 0
            })
            .finally(() => {
              loadingBalance.value = false
            })
        })
    })

    return {
      balance,
      movements,
      loadingBalance,
      showModal,
      pendingAction,
      loadingWithdraw,
      failed,
      doWithdraw,
      getPendingAction
    }
  }
}
</script>

<style scoped>
.button:active {
  border: none;
}
</style>
